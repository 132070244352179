import React, { useState } from 'react';
import { User } from 'authority_sdk';
import { DocumentTextIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '../../../components';


type UsersTableProps = {
  activeUsers: Array<User>;
  deletedUsers: Array<User>;
  currentlyLoggedInUser: User;
  routerPrefix: string;
  deleteUser: (userId: string) => void;
  setSelectedUser: (user: User) => void;
  setShowDelete: (show: boolean) => void;
};

function createUiElementsForUser(user: User, props: UsersTableProps): JSX.Element {
  const userIsDeleted = user.isDeleted === true;
  const userIsCurrentlyLoggedInUser = user.accountId === props.currentlyLoggedInUser.accountId;

  const editButton = (
    <a
      href={`${props.routerPrefix}/admin/user/${user.accountId}/edit`}
      className="text-5minds-blue-800 hover:text-5minds-blue-900"
    >
      <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Edit" />
      <span className="sr-only">, Edit {user.username}</span>
    </a>
  );

  const deleteButton = (
    <a
      className="text-red-700 hover:text-red-800 hover:cursor-pointer"
      onClick={() => {
        props.setSelectedUser(user);
        props.setShowDelete(true);
      }}
    >
      <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Delete" />
      <span className="sr-only">, Delete {user.username}</span>
    </a>
  );

  if (userIsCurrentlyLoggedInUser) {
    const [showDeletionTooltip, setDeletionTooltip] = useState(false);
    const disabledDeletButton = (
      <Tooltip
        message={<p>You can't delete your account.</p>}
        show={showDeletionTooltip!}
        setShow={setDeletionTooltip!}
      >
        <a className="text-gray-300 hover:text-gray-400 hover:cursor-pointer">
          <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Delete" />
          <span className="sr-only">, Delete {user.username}</span>
        </a>
      </Tooltip>
    );

    return (
      <td className="flex gap-4 py-4 px-4 text-right text-sm font-medium">
        {editButton}
        {disabledDeletButton}
      </td>
    );
  }

  const deletedUserMessage = <div className="text-red-700">Deleted</div>;

  if (userIsDeleted) {
    return <td className="flex gap-4 py-4 px-4 text-right text-sm font-medium">{deletedUserMessage}</td>;
  }

  return (
    <td className="flex gap-4 py-4 px-4 text-right text-sm font-medium">
      {editButton}
      {deleteButton}
    </td>
  );
}

export function UsersTable(props: UsersTableProps): JSX.Element {
  return (
    <>
      <table className="divide-gray-300 table-fixed divide-y">
        <thead className="w-full bg-gray-50">
          <tr className="w-full">
            <th scope="col" className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
              Account-ID
            </th>
            <th scope="col" className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
              Username
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="w-full divide-gray-200 bg-white divide-y">
          <tr key={props.currentlyLoggedInUser.accountId} className="w-full">
            <td className="truncate text-gray-600 px-3 py-4 text-sm">{props.currentlyLoggedInUser.accountId}</td>
            <td className="truncate text-gray-600 px-3 py-4 text-sm">{props.currentlyLoggedInUser.username}</td>
            {createUiElementsForUser(props.currentlyLoggedInUser, props)}
          </tr>
          {props.activeUsers
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((user) => (
              <tr key={user.accountId} className="w-full">
                <td className="truncate text-gray-600 px-3 py-4 text-sm">{user.accountId}</td>
                <td className="truncate text-gray-600 px-3 py-4 text-sm">{user.username}</td>
                {createUiElementsForUser(user, props)}
              </tr>
            ))}
          {props.deletedUsers
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((user) => (
              <tr key={user.accountId} className="w-full opacity-50">
                <td className="truncate text-gray-600 px-3 py-4 text-sm">{user.accountId}</td>
                <td className="truncate text-gray-600 px-3 py-4 text-sm">{user.username}</td>
                {createUiElementsForUser(user, props)}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
