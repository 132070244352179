import { Provider } from 'oidc-provider';

export type Interaction = Awaited<ReturnType<Provider['interactionDetails']>>;
export { InteractionResults } from 'oidc-provider';

export * from './extensions';
export * from './rendering';
export * from './claims';
export * from './configuration';
export * from './errors';
export * from './routing';
export * from './external_login_providers';
export * from './Logger';
export * from './external_claim_resolvers';

export type RegisteredFunction<T extends CallableFunction> = T & {
  set: (value: T | null) => void;
  get: () => T;

  getDefault: () => T;
};
