import assert from 'assert';
import { AuthorityBackendSdk } from '..';
import express from 'express';

export function getIssueAllRequestedGrantsRequestHandler(authority: AuthorityBackendSdk): express.RequestHandler {
  return async (req , res): Promise<void> => {
    const oidc = authority.getOidcProvider();

    const interactionDetails = await oidc.interactionDetails(req, res);
    const {
      prompt: { name, details },
      params,
      session,
    } = interactionDetails;
    const accountId = session?.accountId;
    assert.equal(name, 'consent');

    let { grantId } = interactionDetails;
    let grant: any;

    if (grantId) {
      // we'll be modifying existing grant in existing session
      grant = await oidc.Grant.find(grantId);
    } else {
      // we're establishing a new grant
      grant = new oidc.Grant({
        accountId,
        clientId: params.client_id as any,
      });
    }

    if (details.missingOIDCScope) {
      grant.addOIDCScope((details.missingOIDCScope as any).join(' '));
    }
    if (details.missingOIDCClaims) {
      grant.addOIDCClaims(details.missingOIDCClaims);
    }
    if (details.missingResourceScopes) {
      for (const [indicator, scope] of Object.entries(details.missingResourceScopes as any)) {
        grant.addResourceScope(indicator, (scope as string[]).join(' '));
      }
    }

    grantId = await grant.save();

    const consent: any = {};
    if (!interactionDetails.grantId) {
      // we don't have to pass grantId to consent, we're just modifying existing one
      consent.grantId = grantId;
    }

    const result = { consent };

    return oidc.interactionFinished(req, res, result, { mergeWithLastSubmission: true });
  };
}
