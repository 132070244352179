import React, { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Claim, ClaimTypes, Scope } from 'authority_sdk';
import { WithDefaultNavBar, SearchBar, ErrorNotification, SuccessNotification } from '../../../components';
import { sortByName } from '../../../infrastructure';

import { ClaimRenderer } from './ClaimRenderer';
import { CreateClaimDialog } from './CreateClaimDialog';
import { DeleteClaimDialog } from './DeleteClaimDialog';
import { EditClaimDialog } from './EditClaimDialog';

type ClaimsPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  claims: Claim[];
};

export function ClaimsPage(props: ClaimsPageProps): JSX.Element {
  const [claims, setClaims] = useState<Claim[]>(props.claims.sort(sortByName));
  const [selectedClaim, setSelectedClaim] = useState<Claim>(claims[0]);

  const [claimFilter, setClaimFilter] = useState<string>('');
  const [filteredClaims, setFilteredClaims] = useState<Claim[]>(claims);

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  function createClaim(name?: string, description?: string, type?: ClaimTypes) {
    const body = JSON.stringify({ name, type, description });
    fetch(`${props.routerPrefix}/admin/claim/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw Error(await response.text());
        return response.json();
      })
      .then((createdClaim: Claim) => {
        setClaims((claims) => [...claims, createdClaim].sort(sortByName));
        setShowCreateDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function updateClaim(claimId: string, name?: string, description?: string, type?: ClaimTypes) {
    const body = JSON.stringify({ name, description, type });

    fetch(`${props.routerPrefix}/admin/claim/${claimId}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw Error(await response.text());
        return response.json();
      })
      .then((updatedClaim: Claim) => {
        setClaims(claims.map((claim) => (claim.id === updatedClaim.id ? updatedClaim : claim)).sort(sortByName));
        setSuccessMessage(`Claim "${updatedClaim.name}" updated successfully`);
        setShowEditDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function deleteClaim(claimId: string) {
    fetch(`${props.routerPrefix}/admin/claim/${claimId}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) throw Error(await response.text());
        return response.json();
      })
      .then((deletedClaim: Claim) => {
        setClaims((claims) => claims.filter((claim) => claim.id !== deletedClaim.id));
        setShowDeleteDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  useEffect(() => {
    setFilteredClaims(
      claims.filter(
        (claim) =>
          claim.name.toLowerCase().includes(claimFilter.toLowerCase()) ||
          claim.description?.toLowerCase().includes(claimFilter.toLowerCase())
      )
    );
  }, [claimFilter, claims]);

  return (
    <>
      <SuccessNotification message={successMessage} setMessage={setSuccessMessage} autoHide />
      <ErrorNotification message={error} setMessage={setError} />

      <CreateClaimDialog show={showCreateDialog} createClaim={createClaim} hide={() => setShowCreateDialog(false)} />
      <EditClaimDialog
        claim={selectedClaim}
        show={showEditDialog}
        editClaim={updateClaim}
        hide={() => setShowEditDialog(false)}
      />
      <DeleteClaimDialog
        claim={selectedClaim}
        show={showDeleteDialog}
        deleteClaim={deleteClaim}
        hide={() => setShowDeleteDialog(false)}
      />

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <div className="mx-auto max-w-6xl h-full border-x border-gray-200">
          <div className="flex flex-col h-full w-full">
            <div className="flex my-4 px-2 justify-between gap-2 items-center">
              <h2 className="text-2xl font-bold leading-8 text-gray-900">Claims</h2>
              <div className="flex gap-2">
                <button
                  className="relative inline-flex items-center gap-x-1.5 rounded-md bg-5minds-blue-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-5minds-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-5minds-blue-900"
                  onClick={() => setShowCreateDialog(true)}
                  type="button"
                >
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  <div className="hidden lg:block">Create Claim</div>
                </button>
                <div className="hidden w-36 lg:block">
                  <SearchBar onChange={(event) => setClaimFilter(event.target.value)} />
                </div>
              </div>
            </div>
            <div
              tabIndex={-1}
              className="flex flex-col w-full h-full px-2 border-b border-t border-gray-200 overflow-auto"
            >
              <div tabIndex={-1} className="flex flex-col divide-y w-full divide-gray-200">
                {filteredClaims.map((claim) => (
                  <ClaimRenderer
                    claim={claim}
                    key={claim.id}
                    setSelectedClaim={setSelectedClaim}
                    showEditDialog={() => setShowEditDialog(true)}
                    showDeleteDialog={() => setShowDeleteDialog(true)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </WithDefaultNavBar>
    </>
  );
}
