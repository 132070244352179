import express from 'express';
import { Provider } from 'oidc-provider';

import {
  PagePayloadInterseptor,
  RenderPageFunction,
  IdTokenClaimResolver,
  AccessTokenClaimResolver,
  AuthorityOptionsStrict,
  RegisteredFunction,
  Interaction,
  ConfigureExternalLoginProviderFunction,
  ConfigureExternalLoginProviderManagerFunction,
  ExternalClaimResolver,
  ConfigureExternalClaimResolverFunction,
  GroupResolver,
} from '../..';

export type AuthorityBackendSdk = {
  pagePayloadInterseptor: RegisteredFunction<PagePayloadInterseptor>;
  renderPage: RegisteredFunction<RenderPageFunction>;

  configureExternalLoginProvider: ConfigureExternalLoginProviderFunction;
  configureExternalLoginProviderManager: ConfigureExternalLoginProviderManagerFunction;
  configureExternalClaimResolver: ConfigureExternalClaimResolverFunction;

  getOptions(): AuthorityOptionsStrict;

  getOidcProvider(): Provider;

  onReady(callback: () => Promise<void>): void;
};

export type GetInteractionUrl = (ctx: any, interaction: Interaction) => Promise<string>;

export type ConfiguredExternalLoginProvider = {
  name: string;
  router: express.Router;
  routerPrefix: string;

  accessTokenClaimResolver?: AccessTokenClaimResolver;
  idTokenClaimResolver?: IdTokenClaimResolver;
  groupResolver?: GroupResolver;
  interactionUrl: GetInteractionUrl;
};

export type ConfiguredClaimResolver = {
  name: string;

  claimResolver: ExternalClaimResolver;
};

export class ExtensionConfigurationError extends Error {
  constructor(extensionName: string, message: string) {
    super(`Extension ${extensionName}: ${message}`);
    this.name = 'ExtensionConfigurationError';
  }
}
