import { ClaimTypes } from "../../../configuration";

export function createDefaultClaimValue(type: ClaimTypes): string | number | boolean | object {
    switch (type) {
      case ClaimTypes.STRING:
        return '';
      case ClaimTypes.INT:
        return 0;
      case ClaimTypes.BOOLEAN:
        return true;
      case ClaimTypes.OBJECT:
        return {};
      default:
        return '';
    }
  }