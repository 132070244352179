export enum ErrorCodes {
  ContinueError = 100,
  SwitchingProtocolsError = 101,
  ProcessingError = 102,

  // 300-redirect-errors
  MultipleChoicesError = 300,
  MovedError = 301,
  FoundError = 302,
  SeeOtherError = 303,
  NotModifiedError = 304,
  UseProxyError = 305,
  TemporaryRedirectError = 307,
  PermanentRedirectError = 308,

  // 400-client-errors
  BadRequestError = 400,
  UnauthorizedError = 401,
  PaymentRequiredError = 402,
  ForbiddenError = 403,
  NotFoundError = 404,
  MethodNotAllowedError = 405,
  NotAcceptableError = 406,
  ProxyAuthenticationRequiredError = 407,
  RequestTimeoutError = 408,
  ConflictError = 409,
  GoneError = 410,
  LengthRequiredError = 411,
  PreconditionFailedError = 412,
  RequestTooLargeError = 413,
  URLTooLongError = 414,
  UnsupportedMediaTypeError = 415,
  RequestedRangeNotSatisfiableError = 416,
  ExpectationFailedError = 417,
  ImATeapotError = 418,
  AuthenticationTimeoutError = 419,
  PolicyNotFulfilledError = 420,
  MisdirectedRequestError = 421,
  UnprocessableEntityError = 422,
  LockedError = 423,
  FailedDependencyError = 424,
  UpgradeRequiredError = 426,
  PreconditionRequiredError = 428,
  TooManyRequestsError = 429,
  RequestHeaderTooLargeError = 431,
  UnavaliableForLegalReasonsError = 451,

  // 500-server-errors
  InternalServerError = 500,
  NotImplementedError = 501,
  BadGatewayError = 502,
  ServiceUnavaliableError = 503,
  GatewayTimeoutError = 504,
  VersionNotSupportedError = 505,
  InsufficientStorageError = 507,
  LoopDetectedError = 508,
  BandwithLimitExceededError = 509,
  NetworkAuthenticationRequiredError = 511,
}
