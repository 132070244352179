import { ClientMetadata } from 'oidc-provider';
import { Options } from 'sequelize';

import { AuthorityDevelopmentOptions, BackendExtensionConfig } from '..';

export type ConfigClaimContent = {
  type: ClaimType;
  description?: string;
};

export type ConfigScopeContent = {
  claims: Array<string>;
  description?: string;
};

export type ConfigClaims = Record<string, ConfigClaimContent>;

export type ConfigScopes = Record<string, ConfigScopeContent>;

export type ScopeDefinition = {
  name: string;
  description?: string;
  claims?: Array<string>;
};

export type ClaimDefinition = {
  name: string;
  type: ClaimType;
  description?: string;
};

export enum ClaimTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  INT = 'int',
  OBJECT = 'object',
}

export type ClaimType = ClaimTypes.STRING | ClaimTypes.BOOLEAN | ClaimTypes.INT | ClaimTypes.OBJECT;

export type AuthorityOptionsStrict = {
  issuerUrl: URL;
  applicationPort: number;
  accessTokenTTL: number;
  idTokenTTL: number;
  development: AuthorityDevelopmentOptions;
  clients: ClientMetadata[];
  scopes: ScopeDefinition[];
  claims: ClaimDefinition[];
  extensions: {
    path?: string;
    configs: {
      [key: string]: BackendExtensionConfig;
    };
  };
  database: Options;
  jwksPath: string;
  pageTitle: string;
};
