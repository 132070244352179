import { ClaimDefinition, ClaimTypes, ScopeDefinition } from './AuthorityOptionsStrict';

export const processCubeScopes: Array<ScopeDefinition> = [
  {
    name: 'engine_read',
    description:
      'Indicates whether the user is allowed to read process models, cronjob history and subscribe to events.',
    claims: ['can_read_cronjob_history', 'can_read_process_model', 'can_subscribe_to_events'],
  },
  {
    name: 'engine_write',
    description: 'Indicates whether the user is allowed to modify and manage process models and process instances.',
    claims: [
      'can_delete_process_model',
      'can_manage_process_instances',
      'can_terminate_process',
      'can_trigger_messages',
      'can_trigger_signals',
      'can_write_process_model',
    ],
  },
  {
    name: 'engine_etw',
    description: 'Indicates whether the user is allowed to access external tasks.',
    claims: ['can_access_external_tasks'],
  },
  {
    name: 'engine_models_write',
    description: 'Indicates whether the user is allowed to modify and manage process models.',
    claims: ['can_delete_process_model', 'can_write_process_model'],
  },
];

export const processCubeClaims: Array<ClaimDefinition> = [
  {
    name: 'can_access_external_tasks',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to access external tasks.',
  },
  {
    name: 'can_delete_process_model',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to delete process models.',
  },
  {
    name: 'can_manage_process_instances',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to manage process instances.',
  },
  {
    name: 'can_read_cronjob_history',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to read the cronjob history.',
  },
  {
    name: 'can_read_process_model',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to read process models.',
  },
  {
    name: 'can_subscribe_to_events',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to subscribe to events.',
  },
  {
    name: 'can_terminate_process',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to terminate process instances.',
  },
  {
    name: 'can_trigger_messages',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to trigger messages.',
  },
  {
    name: 'can_trigger_signals',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to trigger signals.',
  },
  {
    name: 'can_write_process_model',
    type: ClaimTypes.BOOLEAN,
    description: 'Indicates whether the user is allowed to write process models.',
  },
];
