import React, { useEffect, useState } from 'react';

import { getUrlParam, setUrlParam, sortByName } from '../../../infrastructure';
import { ErrorNotification, SuccessNotification, WithDefaultNavBar } from '../../../components';

import { SelectScopesRenderer } from './SelectScopesRenderer';
import { SelectClaimsRenderer } from './SelectClaimsRenderer';
import { CreateScopeDialog } from './CreateScopeDialog';
import { DeleteScopeDialog } from './DeleteScopeDialog';
import { EditScopeDialog } from './EditScopeDialog';
import { ScopeDetailsDialog } from './ScopeDetailsDialog';
import { Scope, Claim } from 'authority_sdk';

type ScopesPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  scopes: Scope[];
  claims: Claim[];
};

export function ScopesPage(props: ScopesPageProps): JSX.Element {
  const [scopes, setScopes] = useState<Scope[]>(props.scopes.length > 0 ? props.scopes.sort(sortByName) : []);
  const [currentScope, setCurrentScope] = useState<Scope | null>(
    props.scopes.length > 0 ? scopes.find((scope) => scope.name === getUrlParam('scope')) || scopes[0] : null
  );
  const [unselectedClaims, setUnselectedClaims] = useState<Claim[]>(
    props.claims.filter((claim) =>
      currentScope ? !currentScope.claims.map((claim) => claim.name).includes(claim.name) : []
    )
  );

  const [scopeFilter, setScopeFilter] = useState<string>('');
  const [claimFilter, setClaimFilter] = useState<string>('');

  const [selectedScope, setSelectedScope] = useState<Scope | null>(props.scopes.length > 0 ? props.scopes[0] : null);
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  function createScope(name?: string, description?: string) {
    const body = JSON.stringify({ name, description });

    fetch(`${props.routerPrefix}/admin/scope/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((createdScope: Scope) => {
        setScopes((scopes) => [...scopes, createdScope].sort(sortByName));
        setCurrentScope(createdScope);
        setShowCreateDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function updateScope(scopeId: string, body: string) {
    fetch(`${props.routerPrefix}/admin/scope/${scopeId}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedScope: Scope) => {
        setScopes(scopes.map((scope) => (scope.id === updatedScope.id ? updatedScope : scope)).sort(sortByName));
        setCurrentScope(updatedScope);
        setSuccessMessage(`Scope ${updatedScope.name} updated successfully`);
        setShowUpdateDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function deleteScope(scopeId: string) {
    fetch(`${props.routerPrefix}/admin/scope/${scopeId}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((deletedScope: Scope) => {
        setScopes(scopes.filter((scope) => scope.id !== deletedScope.id));
        const deletedScopeWasOnlyScope = scopes.length === 1 && scopes[0].name === deletedScope.name;
        setCurrentScope(deletedScopeWasOnlyScope ? null : scopes[0]);
        setShowDeleteDialog(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function updateScopeDetails(scopeId: string, name?: string, description?: string) {
    const body = JSON.stringify({ name, description });
    updateScope(scopeId, body);
  }

  function updateScopeClaims(scope: Scope) {
    const body = JSON.stringify({ claimIds: scope.claims.map((claim) => claim.id) });
    updateScope(scope.id, body);
  }

  function addClaimToScope(claim: Claim) {
    if (currentScope === null) {
      return;
    }
    fetch(`${props.routerPrefix}/admin/scope/${currentScope.id}/add/claim`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimId: claim.id }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then(() => {
        const newClaims = [...currentScope.claims];
        newClaims.push(claim);
        const newCurrentScope = { ...currentScope, claims: newClaims };
        setCurrentScope(newCurrentScope);
        setScopes(scopes.map((scope) => (scope.name === newCurrentScope.name ? newCurrentScope : scope)));
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function removeClaimFromScope(claim: Claim) {
    if (currentScope === null) {
      return;
    }
    fetch(`${props.routerPrefix}/admin/scope/${currentScope.id}/remove/claim`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimId: claim.id }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then(() => {
        const newClaims = currentScope.claims.filter((currentClaim) => currentClaim.name !== claim.name);
        const newCurrentScope = { ...currentScope, claims: newClaims };
        setCurrentScope(newCurrentScope);
        setScopes(scopes.map((scope) => (scope.name === newCurrentScope.name ? newCurrentScope : scope)));
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  useEffect(() => {
    const filteredScopes = props.scopes.filter((scope) => scope.name.includes(scopeFilter));
    setScopes(filteredScopes.sort(sortByName));
  }, [scopeFilter]);

  useEffect(() => {
    const newUnselectedClaims = props.claims.filter((claim) =>
      currentScope
        ? !currentScope.claims.map((claim) => claim.name).includes(claim.name) && claim.name.includes(claimFilter)
        : false
    );
    setUnselectedClaims(newUnselectedClaims);
  }, [currentScope, claimFilter]);

  useEffect(() => {
    setUrlParam('scope', currentScope !== null ? currentScope.name : '');
  }, [currentScope, selectedScope]);

  return (
    <>
      <SuccessNotification message={successMessage} setMessage={setSuccessMessage} autoHide />
      <ErrorNotification message={error} setMessage={setError} />
      <CreateScopeDialog show={showCreateDialog} hide={() => setShowCreateDialog(false)} createScope={createScope} />
      {selectedScope !== null && (
        <EditScopeDialog
          show={showUpdateDialog}
          scope={selectedScope}
          hide={() => setShowUpdateDialog(false)}
          updateDetails={updateScopeDetails}
        />
      )}
      {selectedScope !== null && (
        <DeleteScopeDialog
          scope={selectedScope}
          show={showDeleteDialog}
          hide={() => setShowDeleteDialog(false)}
          deleteScope={deleteScope}
        />
      )}

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <div className="mx-auto max-w-6xl h-full border-x border-gray-200">
          <div className="flex h-full">
            {' '}
            <div className="flex flex-col w-1/2 h-full border-r border-gray-200">
              <SelectScopesRenderer
                currentScope={currentScope}
                scopes={scopes}
                setCurrentScope={setCurrentScope}
                setScopeFilter={setScopeFilter}
                setSelectedScope={setSelectedScope}
                showCreateDialog={() => setShowCreateDialog(true)}
                showDeleteDialog={() => setShowDeleteDialog(true)}
                showUpdateDialog={() => setShowUpdateDialog(true)}
              />
            </div>
            {currentScope !== null && (
              <div className="flex flex-col w-1/2 h-full">
                <SelectClaimsRenderer
                  currentScope={currentScope}
                  unselectedClaims={unselectedClaims}
                  addClaimToScope={addClaimToScope}
                  removeClaimFromScope={removeClaimFromScope}
                  setClaimFilter={setClaimFilter}
                  updateScopeClaims={updateScopeClaims}
                />
              </div>
            )}
          </div>
        </div>
      </WithDefaultNavBar>
    </>
  );
}
