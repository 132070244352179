import pino from 'pino';

export enum LogLevel {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export type LogObject = { [key: string]: any };

export type LoggerSettings = {
  minLogLevel: LogLevel;
  hostname?: string;
};

let minimumLogLevel: LogLevel;
let hostname: string;
let pinoDestination: any;

// Taken from: https://stackoverflow.com/questions/17575790/environment-detection-node-js-or-browser/31090240#31090240
const isBrowserEnv = new Function('try {return this===window;}catch(e){ return false;}')() as boolean;

const logger = isBrowserEnv ? getPinoInstanceForBrowser() : getPinoInstanceForNode();

function getPinoInstanceForBrowser(): pino.Logger {
  return pino({
    base: {},
    level: LogLevel.debug,
    timestamp: pino.stdTimeFunctions.isoTime,
    browser: {
      asObject: true,
    },
  });
}

function getPinoInstanceForNode(): pino.Logger {
  if (typeof pino.destination === 'function') {
    try {
      pinoDestination = pino.destination({ sync: false, dest: 1 as any });
    } catch {}
  }

  return pino(
    {
      base: {},
      level: LogLevel.trace,
      timestamp: pino.stdTimeFunctions.isoTime,
    },
    pinoDestination
  );
}

export function initLogger(settings: LoggerSettings): void {
  setMinLogLevel(settings.minLogLevel);

  hostname = settings.hostname || process.env.hostname;
}

let globalLogObjects = {};

export function setGlobalLogObjects(logObjects: { [key: string]: any }): void {
  globalLogObjects = logObjects;
}

export function getGlobalLogObjects(): { [key: string]: any } {
  return globalLogObjects;
}

export function setMinLogLevel(minLogLevelToSet: LogLevel): void {
  if (minLogLevelToSet && LogLevel[minLogLevelToSet.toLowerCase()] !== undefined) {
    minimumLogLevel = minLogLevelToSet;
  } else {
    logger.error(`Unknown LogLevel "${minLogLevelToSet}".`);
  }
}

export class Logger {
  private namespace: string;
  private defaultLogObject: LogObject;

  constructor(namespace: string, defaultLogObject?: LogObject) {
    this.namespace = namespace;
    this.defaultLogObject = defaultLogObject ?? {};
  }

  public flush(): void {
    pinoDestination.flushSync();
  }

  public addToDefaultLogObject(key: string, value: any): void {
    this.defaultLogObject[key] = value;
  }

  public removeFromDefaultLogObject(key: string): void {
    delete this.defaultLogObject[key];
  }

  public logForLevel(message: string, level: LogLevel, logObject?: LogObject): void {
    switch (level) {
      case LogLevel.error:
        this.error(message, logObject);
        break;
      case LogLevel.warn:
        this.warn(message, logObject);
        break;
      case LogLevel.info:
        this.info(message, logObject);
        break;
      case LogLevel.debug:
        this.debug(message, logObject);
        break;
      case LogLevel.trace:
        this.trace(message, logObject);
    }
  }

  public error(message: string, logObject?: LogObject): void {
    if (!this.shouldLog(LogLevel.error)) {
      return;
    }

    const logObjectWithDefaults = Object.assign({}, globalLogObjects, this.defaultLogObject, logObject);

    log(LogLevel.error, this.namespace, message, logObjectWithDefaults);
  }

  public warn(message: string, logObject?: LogObject): void {
    if (!this.shouldLog(LogLevel.warn)) {
      return;
    }

    const logObjectWithDefaults = Object.assign({}, globalLogObjects, this.defaultLogObject, logObject);

    log(LogLevel.warn, this.namespace, message, logObjectWithDefaults);
  }

  public info(message: string, logObject?: LogObject): void {
    if (!this.shouldLog(LogLevel.info)) {
      return;
    }

    const logObjectWithDefaults = Object.assign({}, globalLogObjects, this.defaultLogObject, logObject);

    log(LogLevel.info, this.namespace, message, logObjectWithDefaults);
  }

  public debug(message: string, logObject?: LogObject): void {
    if (!this.shouldLog(LogLevel.debug)) {
      return;
    }

    const logObjectWithDefaults = Object.assign({}, globalLogObjects, this.defaultLogObject, logObject);

    log(LogLevel.debug, this.namespace, message, logObjectWithDefaults);
  }

  public trace(message: string, logObject?: LogObject): void {
    if (!this.shouldLog(LogLevel.trace)) {
      return;
    }

    const logObjectWithDefaults = Object.assign({}, globalLogObjects, this.defaultLogObject, logObject);

    log(LogLevel.trace, this.namespace, message, logObjectWithDefaults);
  }

  private shouldLog(logLevel: LogLevel): boolean {
    if (!minimumLogLevel) {
      return true;
    }

    return this.logLevelAsNumber(minimumLogLevel) <= this.logLevelAsNumber(logLevel);
  }

  private logLevelAsNumber(logLevel: LogLevel): number {
    switch (logLevel) {
      case LogLevel.trace:
        return 1;
      case LogLevel.debug:
        return 2;
      case LogLevel.info:
        return 3;
      case LogLevel.warn:
        return 4;
      case LogLevel.error:
        return 5;
      default:
        return 0;
    }
  }
}

function log(level: LogLevel, namespace: string, message: string, logObject?: LogObject): void {
  const metadata = { levelName: level, namespace: namespace, message: message } as any;

  if (hostname) {
    metadata.hostname = hostname;
  }

  const objectToLog = Object.assign(metadata, logObject);

  switch (level) {
    case LogLevel.error:
      logger.error(objectToLog);
      break;
    case LogLevel.warn:
      logger.warn(objectToLog);
      break;
    case LogLevel.info:
      logger.info(objectToLog);
      break;
    case LogLevel.debug:
      logger.debug(objectToLog);
      break;
    case LogLevel.trace:
      logger.trace(objectToLog);
  }
}
