import React, { useState } from 'react';

import { ErrorNotification, WithDefaultNavBar } from '../../../components';

import { DeleteUserDialog } from './DeleteUserDialog';
import { UserDetailsDialog } from './UserDetailsDialog';
import { UsersTable } from './UsersTable';
import { User } from 'authority_sdk';

interface DashboardProps {
  routerPrefix: string;
  currentlyLoggedInAccountId: string;
  logo: string;
  issuerUrl: string;
  users?: Array<User>;
}

export function Dashboard(props: DashboardProps): JSX.Element {
  const currentlyLoggedInUser = props.users?.find((user) => user.accountId === props.currentlyLoggedInAccountId);
  const activeUserFromProps = props.users?.filter(
    (user) => user.isDeleted !== true && user.accountId !== props.currentlyLoggedInAccountId
  );
  const deletedUserFromProps = props.users?.filter((user) => user.isDeleted === true);

  const [activeUsers, setActiveUser] = useState<Array<User>>(activeUserFromProps ?? []);
  const [deletedUsers, setDeletedUsers] = useState<Array<User>>(deletedUserFromProps ?? []);
  const [selectedUser, setSelectedUser] = useState<User>(currentlyLoggedInUser!);

  const [showDelete, setShowDelete] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const fullDelete = false;

  function deleteUser(userId: string): void {
    fetch(`${props.routerPrefix}/admin/user/${userId}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fullDelete }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((deletedUser: User) => {
        if (fullDelete) {
          const updatedActiveUsers = activeUsers.filter((user) => user.accountId !== deletedUser.accountId);
          setActiveUser(updatedActiveUsers);
        } else {
          const updatedUser = activeUsers.find((user) => user.accountId === deletedUser.accountId);
          if (updatedUser) {
            updatedUser.isDeleted = true;
            const updateDeletedUsers = deletedUsers.concat([updatedUser]);
            setDeletedUsers(updateDeletedUsers);
          }

          const updatedActiveUsers = activeUsers?.filter((user) => user.accountId !== deletedUser.accountId);
          setActiveUser(updatedActiveUsers);
        }
        setShowDelete(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />

      <DeleteUserDialog
        routerPrefix={props.routerPrefix}
        user={selectedUser}
        show={showDelete}
        deleteUser={deleteUser}
        hide={() => setShowDelete(false)}
      />

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <div className="mt-8 flex flex-col items-center">
          <div className="ring-black/5 relative overflow-hidden shadow ring-1 md:rounded-lg">
            <UsersTable
              activeUsers={activeUsers}
              deletedUsers={deletedUsers}
              currentlyLoggedInUser={currentlyLoggedInUser!}
              routerPrefix={props.routerPrefix}
              deleteUser={deleteUser}
              setSelectedUser={setSelectedUser}
              setShowDelete={setShowDelete}
            />
          </div>
        </div>
      </WithDefaultNavBar>
    </>
  );
}
