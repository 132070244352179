import { BaseError, ErrorCategory } from './BaseError';
import { ErrorCodes } from './ErrorCodes';

export class AuthenticationTimeoutError extends BaseError {
  constructor(message: string, category?: ErrorCategory) {
    super(ErrorCodes.AuthenticationTimeoutError, message, category);
  }
}

export class BadRequestError extends BaseError {
  constructor(message: string, category?: ErrorCategory) {
    super(ErrorCodes.BadRequestError, message, category);
  }
}

export class ForbiddenError extends BaseError {
  constructor(message: string, category?: ErrorCategory) {
    super(ErrorCodes.ForbiddenError, message, category);
  }
}

export class UnauthorizedError extends BaseError {
  constructor(message: string, category?: ErrorCategory) {
    super(ErrorCodes.UnauthorizedError, message, category);
  }
}
