import * as errorClasses from './index';

export function isAuthorityError(error: Error | BaseError | any): boolean {
  return error instanceof Error && (error as BaseError).isAuthorityError === true;
}

export type IClassOf<TClass> = new (...args: Array<any>) => TClass;

export type ErrorCategory = 'process' | 'setup' | 'extension';

export class BaseError extends Error {
  public code: number;
  public additionalInformation: any;
  public category?: ErrorCategory;
  public fatal = false;

  public isAuthorityError = true;

  constructor(code: number, message: string, category?: ErrorCategory) {
    super(message);
    this.code = code;
    this.name = this.constructor.name;
    this.category = category;

    this.setCategoryPrefix();

    const captureStackTraceMethodAvailable = (Error as any).captureStackTrace !== undefined;
    if (captureStackTraceMethodAvailable) {
      // For NodeJS and Chrome
      (Error as any).captureStackTrace(this, this.constructor);
    } else {
      // Fallback for others like Firefox, IE 10+, Safari etc.
      this.stack = new Error().stack ?? '';
    }
  }

  public serialize(): string {
    return JSON.stringify({
      errorClassName: this.constructor.name,
      code: this.code,
      message: this.message,
      callStack: this.stack,
      additionalInformation: this.additionalInformation,
    });
  }

  private setCategoryPrefix() {
    const messageIncludesCategoryAlready = !this.category || this.message.startsWith(`Failure in ${this.category}:`);

    if (messageIncludesCategoryAlready) {
      return;
    }

    this.message = `Failure in ${this.category}: ${this.message}`;
  }
}
